* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #ddd;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfcfd !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
  div#root {
    background-color: #fff;
  }
  html,
  body {
    background-color: #fff;
  }

  header,
  button {
    display: none !important;
  }
}
