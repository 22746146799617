.card {
  background: #fff;
  border-radius: 2px;
  padding: 20px 20px;
  min-height: 10em;
  //   padding-bottom: 2em !important;
  //   display: inline-block;
  margin: 1rem;
  position: relative;
  width: 30%;
  box-shadow: 0 0px 2px rgba(#000, 0.2);
  cursor: pointer;
  transition: 0.3s ease;
  //   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &:hover {
    box-shadow: 0 2px 4px rgba(#000, 0.2), 0 4px 8px rgba(#000, 0.2);
  }

  &:active {
    box-shadow: none;
    transform-origin: center;
    transform: scale(0.98);
  }
}

@media (max-width: 450px) {
  .card {
    margin: 1rem auto;
    width: 90%;
  }
}

.project__header {
  text-transform: capitalize;
  font-size: 28px;
  font-weight: 700;
  color: #ee6352;
  margin: 0em;
}

.project__description {
  font-size: 18px;
  margin: 0;
  color: #606060;
  margin-left: 0.1em;
}

.project__flat {
  color: #202020;
  margin: 0;
  // display: flex;
  font-size: 16px;
  margin-top: 0.5em;
  margin-left: 0.1em;
  //   justify-content: space-around;
}

.project__flat__title {
  font-weight: 600;
  margin-right: 0.1em;
}
