.tableborder{
    border:2px solid rgb(157, 157, 157) ;
}
.flexBoxfull{
    flex: 1 0 50%;
    margin: 5px;
}
.flexBoxhalf{
    flex: 1 0 40%;
    margin: 5px;
}
